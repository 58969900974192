import React from "react";
import  { Link } from 'react-router-dom';

import numerologia1 from '../../../assets/images/numerologia1.png';
import numerologia2 from '../../../assets/images/numerologia2.png';
import numerologia3 from '../../../assets/images/numerologia3.png';
import numerologia4 from '../../../assets/images/numerologia4.png';
import numerologia5 from '../../../assets/images/numerologia5.png';

import './styles.css';

const Numerology: React.FC = () => {
  return (
    <div className="numerology">
      <div className="numerology-content">
        <strong>MENTORIA</strong>
        <strong>AS 5 GRANDES NUMEROLOGIAS</strong>
        <p>AS MENTORIAS ONLINE, FORAM DESENVOLVIDAS PARA OS PROFISSIONAIS QUE DESEJAM PRATICAR E SE
          ESPECIALIZAR NAS TÉCNICAS MAIS PROFUNDAS DA INTERPRETACÃO DA NUMEROLOGIA.</p>
        
        <div className="items1">

          <Link to='/cursos/a5bbb184-b1a6-440d-b513-2dbb248404bf' style={{ textDecoration: 'none' }}>
            <div className="item">
              <img src={numerologia1} alt="Numerologia_Pitagorica"/>
              <div className="numerology-title">
                <strong>Numerologia Pitagórica</strong>
              </div>
            </div>
          </Link>

          <Link to='/cursos/e6c0ff92-e242-4511-9165-9163421171a0' style={{ textDecoration: 'none' }}>
            <div className="item">
              <img src={numerologia2} alt="Numerologia_Carmatica"/>
              <div className="numerology-title">
                <strong>Numerologia Carmática</strong>
              </div>
            </div>
          </Link>          

          <Link to='/cursos/b15739ee-4092-4dd6-9092-4ff7692c8235' style={{ textDecoration: 'none' }}>
            <div className="item">
              <img src={numerologia3} alt="Numerologia_Cabalistica"/>
              <div className="numerology-title">
                <strong>Numerologia Cabalística</strong>
                <strong>(Os Ciclos da Vida)</strong>
              </div>
            </div>
          </Link>
          
        </div>
        <div className="items2">

          <Link to='/cursos/3ea45df1-e426-46f4-a9ba-20328d2a2bd9' style={{ textDecoration: 'none' }}>
            <div className="item">
              <img src={numerologia4} alt="Numerologia_Tantrica"/>
              <div className="numerology-title">
                <strong>Numerologia Tântrica</strong>
              </div>
            </div>
          </Link>
          
          <Link to='/cursos/929b18aa-7a96-47f9-9767-1f2508642f0e' style={{ textDecoration: 'none' }}>
            <div className="item">
              <img src={numerologia5} alt="Numerologia_Projetiva"/>
              <div className="numerology-title">
                <strong>Numerologia Projetiva</strong>
              </div>
            </div> 
          </Link>
         
        </div>

        <p id="ministrada-por">Aulas ministradas pela renomada Numeróloga, <em>Ingrid Dalila Engel</em>. 
          Evolua sua carreira com a orientação de uma autoridade reconhecida com
          mais de 40 anos de experiência e milhares de clientes recorrentes.</p>      
      </div>
    </div>
  ) 
};

export default Numerology;

import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import imgEscola from '../../../assets/images/banner_escola.png';
import imgCursos from '../../../assets/images/banner_livros.png';
import imgFaq from '../../../assets/images/faq.png';
import imgFree from '../../../assets/images/banner_free.png';
import imgLabelFree from '../../../assets/images/label_free.png';

import {
  mainStyle,
  cardStyle,
  titleBoxStyle,
  h2Style,
  imageBoxStyle,
  imgStyle,
  buttonBoxStyle,
  buttonStyle,
  infoBoxStyle,
  h1Style,
  cardBoxStyle,
  separatorStyle,
  imgFaqStyle,
  freeBannerStyle,
  freeLabelStyle,
  linkStyle,
  strongStyle,
} from './styles';

const Main: React.FC = () => {
  return (
    <Box sx={mainStyle}>

      <Box component='a' sx={linkStyle} href='https://aluno.escoladenumerologia.com.br' target='_blank' rel='noreferrer noopener'>  
        <Box component='img' sx={freeLabelStyle} src={imgLabelFree} alt='conteudo_gratuito'/>     
      </Box>
      <Box component='a' sx={linkStyle} href='https://aluno.escoladenumerologia.com.br' target='_blank' rel='noreferrer noopener'>    
        <Tooltip 
          title="Cadastre-se e tenha acesso ao Material Gratuito exclusivo"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
        > 
          <Box component='img' sx={freeBannerStyle} src={imgFree} alt='conteudo_gratuito'/>
        </Tooltip>
      </Box>

      <Box component='a' sx={strongStyle} href='https://aluno.escoladenumerologia.com.br' target='_blank' rel='noreferrer noopener'>    
        <Box component='strong' >Cadastre-se e tenha acesso ao Material Gratuito exclusivo</Box>
      </Box>

      <Box sx={infoBoxStyle}>
        <Box sx={h1Style}>Cursos e Mentorias</Box>        
      </Box>
      <Box sx={separatorStyle}></Box>
      <Box sx={cardBoxStyle}>
        <Tooltip 
          title="Saiba mais sobre nossas Mentorias"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
        > 
          <Box sx={cardStyle}>
            <Box sx={titleBoxStyle}>
              <Box component='h2' sx={h2Style}>MENTORIAS</Box>
            </Box>
            <Link to='/mentorias' style={{ textDecoration: 'none' }}>
              <Box sx={imageBoxStyle}>
                <Box component='img' src={imgEscola} alt='mentorias' sx={imgStyle} />
              </Box>
            </Link>
            <Box sx={buttonBoxStyle}>
              <Link to='/mentorias' style={{ textDecoration: 'none' }}>
                <Box component='button' sx={buttonStyle}>ACESSAR</Box>
              </Link>
            </Box>
          </Box>
        </Tooltip>

        <Tooltip 
          title="Saiba mais sobre nossos Cursos para Iniciantes"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
        > 
          <Box sx={cardStyle}>
            <Box sx={titleBoxStyle}>
              <Box component='h2' sx={h2Style}>CURSOS</Box>
            </Box>
            <Link to='/cursos' style={{ textDecoration: 'none' }}>
              <Box sx={imageBoxStyle}>
                <Box component='img' src={imgCursos} alt='cursos' sx={imgStyle} />
              </Box>
            </Link>
            <Box sx={buttonBoxStyle}>
              <Link to='/cursos' style={{ textDecoration: 'none' }}>
                <Box component='button' sx={buttonStyle}>ACESSAR</Box>
              </Link>
            </Box>
          </Box>
        </Tooltip>
      </Box>

      <Tooltip 
        title="Veja respostas para as dúvidas mais frequentes"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
      >
        <Link to='/perguntas-mais-frequentes' style={{ textDecoration: 'none' }}>
          <Box component='img' sx={imgFaqStyle} src={imgFaq} alt='perguntas_frequentes'/>
        </Link>
      </Tooltip>

    </Box>
  );
}

export default Main;

import Box from '@mui/system/Box';

import ICourse from '../../../data/interfaces/ICourse';

import DateUtils from '../../../utils/dateUtils';


import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  thumbnailBoxStyle,
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,
  valueStyle,
  buttonPagBankStyle,
  linkStyle,
  modelStyle,
  modalCloseIcon,
  modalMessageStyle,
  notReleasedStyle,
  releasedInStyle,
} from './styles';

interface Props { 
  course: ICourse;  
}
type props = Props

const ESCOLAL_URL = 'https://aluno.escoladenumerologia.com.br/cursos/';

const CourseInfo = ({ course }: props) => {
  const dateUtils = DateUtils(); 

  return (
    <Box sx={mainStyle}>           
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{course.title}</Box> 
        </Box>
        <Box sx={thumbnailBoxStyle}>
          <Box component='img' sx={imgStyle} src={course.thumbnail} alt='thumbnail'/>
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{course.workload}</Box> 
        </Box>
        <Box component='p' sx={descriptionStyle}>{course.description}</Box>
        {
          !course.isReleased ?           
            (course.workload === '' && course.value == 0 ) ?
              <Box sx={notReleasedStyle}>
                <Box component='strong' sx={releasedInStyle}>EM BREVE</Box>                
              </Box> 
            :
              <Box sx={notReleasedStyle}>
                <Box component='strong' sx={releasedInStyle}>DISPONÍVEL EM {dateUtils.handleDate(course.releaseDate)}</Box>
                <Box component='strong'sx={{...releasedInStyle, color: 'var(--color-text-value)'}}>VALOR: R$ {course.value}</Box>
              </Box>      
          :
            <Box sx={buttonBoxStyle}>
              <Box component='a' sx={linkStyle} href={ESCOLAL_URL+course.uid} target='_blank' rel='noreferrer noopener'>
                <Box component='button' sx={buttonStyle} type='button'>COMPRE AQUI - VALOR: R$ {course.value}</Box>
              </Box>               
            </Box>
        }
      </Box>
    </Box>
  );
};

export default CourseInfo;

import React from 'react';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import fotoIngrid from '../../../assets/images/fotos/ingrid_page.png';
import assinatura from '../../../assets/images/assinatura.png';
import seta from '../../../assets/images/seta.png';

import './styles.css';

const Ingrid: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Escola de Numerologia');

  const ingridLink = 'https://www.truenumbers.com.br/quem-somos';

  return (
    <div className="ingrid">
      <div className="ingrid-content">       
        <div className="fundo">
          <div className="ingrid-main">
            <h2>APRENDA NUMEROLOGIA COM
              QUEM ENTENDE PROFUNDAMENTE DISSO</h2>
            <div className="block">
              <div className="image">
                <img src={fotoIngrid} alt="foto_ingrid" />
                <img id="seta" src={seta} alt="seta_para_conheca_ingrid" />
              </div>
              <div className="text">
                <p>"O meu propósito, através da consciência e
                  do conhecimento é facilitar um
                  caminho dentro do propósito maior, na
                  verdade e mais feliz."</p>
                <p id='com-carinho'>Com carinho,</p>
                <img src={assinatura} alt="Ingrid Dalila Engel" />                              
              </div>
            </div>
          </div>
        </div>       
      </div>
    </div>
  );
}
  
export default Ingrid;
  